import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DateFormat from '../DateFormat';

const SecurityEvents = ({ dashboardData }) => {
    const [securityEvents, setSecurityEvents] = useState([]);

    useEffect(() => {
        setSecurityEvents(dashboardData.sort((a, b) => new Date(b.analyze_time) - new Date(a.analyze_time)));
    }, [dashboardData]);

    const analyzeTimeBody = (rowData) => {
        return (
            <>
                <DateFormat dateString={rowData?.analyze_time} />

            </>
        )
    }
    const iconBody = (rowData) => {
        if (rowData.verdict === 'phishing') {
            return (
                <>
                    <small className='pi pi-shield phishingIconColor'></small>
                </>
            )
        } else if (rowData.attachment_verdict === 'attachment_extension_in_blacklist') {
            return (
                <>
                    <small style={{ color: 'orange' }} className='pi pi-file'></small>
                </>
            )
        }
        else if (rowData.sender_domain_verdict === 'blacklist') {
            return (
                <>
                    <small style={{ color: '#ff6259' }} className='pi pi-ban'></small>
                </>
            )
        } else if (rowData.verdict === 'content_words_in_blacklist') {
            return (
                <>
                    <small style={{ color: '#ff6259' }} className='pi pi-book'></small>
                </>
            )
        } else {
            return (
                <>
                    <small style={{ color: '#ff6259' }} className='pi pi-ban'></small>
                </>
            )
        }

    }

    const verdictBody = (rowData) => {
        let data = rowData?.verdict?.replace(/_/g, ' ');

        return (
            <>
                {
                    data === "attachment extension in blacklist" ? "Attachment Filter"
                        : data === "sender domain in blacklist" ? "Domain Filter"
                            : data === "content words in blacklist" ? "Content Filter"
                                : data === "attachment bigger than max size" ? "Attachment Max Size"
                                    : data.replace(/\b\w/g, char => char.toUpperCase())
                }
            </>
        )
    }
    const messageBody = (rowData) => {
        if (rowData.attachment_verdict === 'malicious') {
            return (
                <>
                    <span> <small style={{ color: '#ff6259', fontSize: '11px' }}>Phishing</small> detected due to <i style={{ color: '#ff6259' }}>'Attachments'</i></span>
                </>
            )
        } else if (rowData.sender_domain_verdict === 'malicious') {
            return (
                <>
                    <span><small style={{ color: '#ff6259', fontSize: '11px' }}>Phishing</small> detected due to <i style={{ color: '#ff6259' }}>'Sender Domain'</i></span>
                </>
            )
        } else if (rowData.urls_verdict === 'malicious') {
            return (
                <>
                    <span><small style={{ color: '#ff6259', fontSize: '11px' }}>Phishing</small> detected due to <i style={{ color: '#ff6259' }}>'Url'</i></span>
                </>
            )
        }
        else if (rowData.attachment_verdict === 'attachment_extension_in_blacklist') {
            return (
                <>
                    <span>Extension :  <small className='darkSmall' style={{ fontSize: '10px' }}>{rowData?.email_content?.attachment?.[0]?.extension}</small></span>
                </>
            )
        }
        else if (rowData.sender_domain_verdict === 'blacklist') {

            return (
                <>
                    <span>Domain :  <small className='darkSmall' style={{ fontSize: '10px' }}>{rowData.sender?.split('@')[1]}</small></span>
                </>
            )
        } else if (rowData.verdict === 'content_words_in_blacklist') {

            return (
                <>
                    <span>Matches Word :  <small className='darkSmall' style={{ fontSize: '10px' }}>{rowData?.message?.matches_words[0].toUpperCase()}</small></span>
                </>
            )
        }
        else if (rowData.verdict === 'spam') {

            return (
                <>
                    <span>SPF :  <small className='darkSmall' style={{ fontSize: '10px' }}>{rowData?.spf_value.toUpperCase()}</small></span>
                </>
            )
        }
        else if (rowData.verdict === "attachment_bigger_than_max_size") {

            return (
                <>
                    <small className='darkSmall' style={{ fontSize: '9px' }}>Higher than the maximum file size</small>
                </>
            )
        }
        else if (rowData.sender_domain_verdict === 'suspicious') {
            return (
                <>
                    <span>SPF :  <small className='darkSmall' style={{ fontSize: '10px' }}>{rowData?.spf_value.toUpperCase()}</small></span>
                </>
            )
        }
        else {
            return (
                <>
                    <span>-</span>
                </>
            )
        }

    }


    return (

        <div style={{height:'31rem'}} className='card'>
            <DataTable
                header='Security Events'
                value={securityEvents}
                // tableStyle={{ minWidth: '43.2rem' }}
                scrollHeight='350px'
                className='data-table-container'
                emptyMessage='There is no daily e-mail traffic.'
                showGridlines
            >
                <Column
                    header='Analyze Time'
                    style={{ fontSize: '10px', textAlign: 'left', width: "20%" }}
                    body={analyzeTimeBody}
                    alignHeader={'left'}
                ></Column>
                <Column
                    style={{ fontSize: '10px', textAlign: 'center', width: "10%" }}
                    body={iconBody}
                ></Column>
                <Column
                    header='Verdict'
                    style={{ fontSize: '10px', textAlign: 'left', width: "27%" }}
                    body={verdictBody} ></Column>
                <Column
                    header='Reason'
                    style={{ fontSize: '10px', textAlign: 'left' }}
                    body={messageBody}
                ></Column>
            </DataTable>
        </div>



    )
}

export default SecurityEvents
