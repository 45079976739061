import React from 'react';
import './reporting.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const WordList = ({ wordlist }) => {

    const formattedData = wordlist.map((item, index) => {
        return {
            word: item[0],
            count: item[1]
        };
    });

    return (
        <div style={{ padding: "5px", margin: "0 auto", height: '280px' }}>
            {/* Başlık */}
            <h6 className="titleAttack">
                Most caught words in content filtering
            </h6>

            {/* Maillerin Listesi */}
            <div style={{ maxHeight: '300px' }}>
                <DataTable showGridlines value={formattedData} responsiveLayout="scroll">
                    <Column headerStyle={{ color: '#1188e2', fontWeight: 'bold' }} style={{ textAlign: 'center', width: '250px' }}
                        alignHeader={'center'} field="word" header="Word" />
                    <Column headerStyle={{ color: '#1188e2', fontWeight: 'bold' }} style={{ textAlign: 'center' }}
                        alignHeader={'center'} field="count" header="Number of catches" />
                </DataTable>
            </div>


            {/* Açıklama */}
            <p style={{ textAlign: "left", marginTop: "10px", color: "#666", fontSize: "11px" }}>
                The words shown in the table show the most blocked words among the banned words. You can increase your email security by adding more words.             </p>
        </div>



    );
}

export default WordList;
