import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getEvents } from '../../services/Api/userAction';
import { useDispatch } from 'react-redux';
import Loading from '../UI/Loading';
import DateFormat from '../DateFormat';
const Events = () => {
  const [action, setAction] = useState();
  const [loading, setLoading] = useState(true);
  const [log, setLog] = useState();
  const [activeTab, setActiveTab] = useState('action');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents).then((res) => {
        console.log("res",res?.data?.results?.data);
      if (res?.data?.results?.data) {
        setLoading(false)
        const sortedDates = res?.data?.results?.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setLog(sortedDates.filter(item => item.action_type === "User Activity"));
        setAction(sortedDates.filter(item => item.action_type !== "User Activity"));
      }

    });
  }, []);

  const timestamp = (rowData) => {

    return (
      <>
        <DateFormat dateString={rowData?.timestamp} />
      </>
    )
  }


  return (
    <>
      {
        loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <div style={{ "justify-content": "space-around" }} className='flex card col-12 p-1'>
              <div className='flex col-6'
                onClick={() => setActiveTab('action')}
                style={{
                  borderRadius: '4px',
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: activeTab === 'action' ? '#49df27' : '#dfddddd4',
                  cursor: 'pointer'
                }}
              >      <small className='eventHeader' >Action</small>  
              </div>
              <div className='flex col-6 ml-1 p-0'
                onClick={() => setActiveTab('logs')}
                style={{
                  borderRadius: '4px',
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: activeTab === 'logs' ? '#49df27' : '#dfddddd4',
                  cursor: 'pointer'
                }}
              >       <small className='eventHeader' >Logs</small> 
              </div>


            </div>
            <div className='card' style={{ padding: '20px' }}>
              {activeTab === 'action' && (
                <DataTable
                  value={action}
                  responsiveLayout="scroll"
                  emptyMessage="No data found."
                  className='p-datatable-gridlines'

                >
                  <Column style={{ textAlign: 'center' }}
                    alignHeader={'center'} field="username" header="User"></Column>
                  <Column
                    style={{ textAlign: 'center' }}
                    alignHeader={'center'}
                    field="action_details"
                    header="Action"
                  ></Column>
                  <Column style={{ textAlign: 'center' }}
                    alignHeader={'center'} header="Time" body={timestamp}></Column>
                </DataTable>
              )}
              {activeTab === 'logs' && (
                <DataTable
                  value={log}
                  responsiveLayout="scroll"
                  className='p-datatable-gridlines'
                  emptyMessage="No data found."

                >
                  <Column style={{ textAlign: 'center' }}
                    alignHeader={'center'} field="username" header="User"></Column>
                  <Column style={{ textAlign: 'center ' }}
                    alignHeader={'center'} field="action_details" header="Action"></Column>
                  <Column style={{ textAlign: 'center' }}
                    alignHeader={'center'} header="Time" body={timestamp}></Column>
                </DataTable>
              )}
            </div>
          </>

        )
      }
    </>
  )
}

export default Events