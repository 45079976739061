import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { faRocket, faLightbulb, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChangeConfirmationModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const hasSeenChanges = localStorage.getItem('hasSeenChanges');
        if (!hasSeenChanges) {
            setShowModal(true);
        }
    }, []);

    const handleConfirmation = () => {

        localStorage.setItem('hasSeenChanges', 'true');
        setShowModal(false);


    };


    const headerTemplate = () => {
        return (
            <>

                <div className='flex flex-column justify-content-center align-items-center' style={{ textAlign: 'center' }}>
                    <img
                        src='/assets/layout/images/ssx.png'
                        alt="Logo"
                        style={{ width: '200px', height: 'auto' }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className='p-0 m-0'>New Features</p>
                        <FontAwesomeIcon className='ml-2' icon={faRocket} size="1x" />
                    </div>


                </div>

            </>
        )
    }

    return (
        <Dialog style={{ width: '600px' }} header={headerTemplate} visible={showModal} onHide={() => setShowModal(false)} >


            <div className='flex justify-content-between align-items-start mt-4'>
                <div className='flex flex-column' style={{ flex: 1 }}>
                    <h6 style={{ fontWeight: 'bold' }}>Reporting page is available</h6>
                    <p style={{ fontSize: '16px' }}>By querying by date range, you can get information about your system and work to make your system more secure.</p>
                    <p style={{ fontSize: '12px' }}>The reports you create are not saved. Do not forget to download the report as a pdf to save it.</p>
                </div>
                <div className='flex justify-content-end'>
                    <img
                        src='/assets/layout/images/reportC1.png'
                        alt="Report Example"
                        style={{ width: '300px', height: 'auto' }}
                    />
                </div>
            </div>
            <div className="flex justify-content-end mt-4 p-2">
                <Button label="I read the changes" icon="pi pi-check" onClick={handleConfirmation} />
            </div>



        </Dialog>
    );
};

export default ChangeConfirmationModal;
