import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { Button } from "primereact/button";

const ExcelExport = ({ data }) => {
    console.log("data2424", data);

    const createDownLoadData = () => {
        handleExport().then((url) => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute("download", 'Sender Domain.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };

        const wbout = XLSX.write(workbook, wopts);

        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });

        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);

        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i);
        }

        return buf;
    };

    const verdict = (param) => {
        let data = param?.replace(/_/g, ' ');
        switch (data) {
            case 'spam':
                return 'Spam';
            case 'content words in blacklist':
                return 'Content Filter';
            case 'blacklist':
                return 'Domain Filter';
            case 'malicious':
                return 'Malicious';
            case 'phishing':
                return 'Phishing';
            case 'attachment extension in blacklist':
                return 'Extensions Filter';
            case 'attachment bigger than max size':
                return 'Attachment Max Size';
            case 'suspicious':
                return 'Suspicious';
            default:
                return 'Unknown';
        }
    };

    const handleExport = () => {
        const title = [{
            A: `Sender Domain Details / Report Date : ${new Date().toLocaleDateString()}`
        }, {}];

        let table1 = [
            {
                A: "Domain",
                B: "Verdict",
            },
        ];

        data.forEach((row) => {
            table1.push({
                A: row.domain,
                B: verdict(row.verdict),
            });
        });

        const finalData = [...title, ...table1, [""]];

        const wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, sheet, 'SenderDomainList');

        const workbookBlob = workbook2blob(wb);

        const headerIndexes = [];
        finalData.forEach((data, index) =>
            data["A"] === "Domain" ? headerIndexes.push(index) : null
        );

        const totalRecords = data.length;

        const dataInfo = {
            titleRange: "A1:B2",
            tbodyRange: `A3:B${finalData.length}`,
            theadRange: headerIndexes.length ? `A${headerIndexes[0] + 1}:B${headerIndexes[0] + 1}` : null,
        };

        return addStyle(workbookBlob, dataInfo);
    };

    const addStyle = (workbookBlob, dataInfo) => {
        return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                sheet.usedRange().style({
                    fontFamily: "Arial",
                    verticalAlignment: "center",
                });

                // Domain sütun genişliği 55, Verdict sütun genişliği 30
                sheet.column("A").width(55);  // Domain sütunu
                sheet.column("B").width(30);  // Verdict sütunu

                if (dataInfo.titleRange) {
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                }

                if (dataInfo.tbodyRange) {
                    sheet.range(dataInfo.tbodyRange).style({
                        horizontalAlignment: "center",
                    });
                }

                if (dataInfo.theadRange) {
                    sheet.range(dataInfo.theadRange).style({
                        fill: "49df27",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                }
            });

            return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
        });
    };

    return (
        <Button
            label="Export"
            icon="pi pi-upload"
            className="ml-3 button-export"
            style={{ backgroundColor: "transparent" }}
            onClick={createDownLoadData}
        />
    );
};

export default ExcelExport;
