import React from 'react';
import './reporting.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFilter, faExclamationTriangle, faEnvelope, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const DataCards = ({ veri }) => {

    // const totals = {};

    // if (dashboardVeri && dashboardVeri?.email_analysis_count) {
    //     for (const dayDataKey in dashboardVeri?.email_analysis_count) {
    //         const dayData = dashboardVeri?.email_analysis_count[dayDataKey];
    //         console.log("dayDat", dayData);

    //         if (dayData && typeof dayData === 'object') {
    //             for (const key in dayData) {
    //                 const value = dayData[key];
    //                 if (totals[key]) {
    //                     totals[key] += value;
    //                 } else {
    //                     totals[key] = value;
    //                 }
    //             }
    //         }
    //     }
    // }
    // console.log('totals', totals);
    // const totalsFileAnalysis = {};

    // if (dashboardVeri && dashboardVeri.file_analysis_count) {
    //     for (const dayDataKey in dashboardVeri.file_analysis_count) {
    //         const dayData = dashboardVeri.file_analysis_count[dayDataKey];

    //         if (dayData && typeof dayData === 'object') {
    //             for (const key in dayData) {
    //                 const value = dayData[key];
    //                 if (totalsFileAnalysis[key]) {
    //                     totalsFileAnalysis[key] += value;
    //                 } else {
    //                     totalsFileAnalysis[key] = value;
    //                 }
    //             }
    //         }
    //     }
    // }
    console.log("veri", veri);
    return (
        <div style={{ padding: "5px", maxWidth: "400px", margin: "0 auto", height: '320px' }}>
            <h6 className="title mt-1 mb-2" >
                System summary
            </h6>
            <div className="card-container">
                <div className="cardAnaliz">
                    <div className="icon-container-phishingMalware">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Phishing</h6>
                        <p>{veri.phishing_count}</p>
                    </div>
                </div>

                <div className="cardAnaliz">
                    <div className="icon-container-spam">
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Spam</h6>
                        <p>{veri.spam_count}</p>
                    </div>
                </div>
                <div className="cardAnaliz">
                    <div className="icon-container-content">
                        <FontAwesomeIcon icon={faFilter} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Content</h6>
                        <p>{veri.content_filter_count}</p>
                    </div>
                </div>
                <div className="cardAnaliz">
                    <div style={{ marginTop: '10px', marginBottom: '2px' }} className="icon-container">
                        <FontAwesomeIcon icon={faFileAlt} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Total</h6>
                        <p>{veri.total_mail_count}</p>
                    </div>
                </div>
            </div>
            <small style={{ textAlign: "left", marginTop: "10px", color: "#666", fontSize: "11px" }}>
                <small style={{ color: 'black', fontWeight: 'bold', fontSize: '11px' }}>Phishing :</small>  This card shows the phishing attacks, one of the most dangerous threats that aim to steal user credentials or sensitive information.
            </small><br />
            <small style={{ textAlign: "left", marginTop: "2px", color: "#666", fontSize: "11px" }}>
                <small style={{ color: 'black', fontWeight: 'bold', fontSize: '11px' }}>Spam :</small>  Spam refers to unsolicited emails, often filled with advertisements or irrelevant content, sent without the recipient's consent.
            </small><br />
            <small style={{ textAlign: "left", padding: '0', marginTop: "2px", color: "#666", fontSize: "11px" }}>
                <small style={{ color: 'black', fontWeight: 'bold', fontSize: '11px' }}>Content Filter :</small>   The content filter blocks emails containing harmful or inappropriate content based on specific keywords.
            </small>
        </div>

    );
};

export default DataCards;
