import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import Color from '../../components/Colors';

const EarthReactSimple = ({ threatData }) => {
    const [countriesData, setCountriesData] = useState([]);

    useEffect(() => {
        axios.get('/country.json')
            .then((response) => {
                setCountriesData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching the data", error);
            });
    }, []);

    const getColorByVerdict = (verdict) => {
        switch (verdict.toUpperCase()) {
            case 'PHISHING':
                return Color[0].phishing;
            case 'MALICIOUS':
                return Color[0].malicious;
            case 'SUSPICIOUS':
                return Color[0].suspicious;
            case 'SPAM':
                return Color[0].spam;
            case 'SENDER DOMAIN BLACKLIST':
                return Color[0].sdb;
            case 'ATTACHMENT EXTENSION BLACKLIST':
                return Color[0].aeb;
            case 'CONTENT WORDS IN BLACKLIST':
                return Color[0].cwb;
            case 'NO THREAT FOUND':
                return Color[0].ntf;
            default:
                return '#c6fffe'; // Varsayılan renk
        }
    };

    return (

        <div className='dashboardCardBack' style={{  borderRadius: '10px', padding: '10px' }}>
            <small className="flex justify-content-center" style={{ fontSize: "16px", fontWeight: 'bold' }}>
                International Email Security Threat Map
            </small>
            <div style={{ width: '100%', maxWidth: '600px', maxHeight: '400px', margin: '0 auto' }}>
                <ComposableMap
                    projectionConfig={{ scale: 150 }}
                    width={600}
                    height={400}
                >
                    <Geographies geography={countriesData}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                console.log("geo", geo);

                                // Find the matching country data
                                const countryData = threatData.find(
                                    (countryItem) => countryItem.Country === geo.id
                                );

                                // If countryData exists, add the geo.properties.name to the object
                                if (countryData) {
                                    countryData.geoName = geo.properties.name;
                                }

                                const fillColor = countryData
                                    ? getColorByVerdict(countryData.Verdict)
                                    : '#e5e5e5';

                                const tooltipContent = countryData
                                    ? `
                                    ${countryData.geoName.toUpperCase()}
                                    Verdict: ${countryData.Verdict}
                                   Count: ${countryData.Count}
        `
                                    : '';


                                const geoId = `geo-${geo.id}`;

                                return (
                                    <g key={geo.rsmKey}>
                                        <Geography
                                            id={geoId}
                                            geography={geo}
                                            fill={fillColor}
                                            stroke="#FFF"
                                            data-pr-tooltip={tooltipContent}
                                            data-pr-position="top"
                                            data-pr-html="true"
                                            data-pr-mousetrack="true"


                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            style={{
                                                stroke: "#FFF",
                                                cursor: 'default',
                                            }}
                                        />
                                        {countryData && (
                                            <Tooltip
                                                style={{ backgroundColor: '#9ea3a9', padding: '2px' }}
                                                target={`#${geoId}`} content={<div dangerouslySetInnerHTML={{ __html: tooltipContent }} />} />
                                        )}
                                    </g>
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
            </div>
        </div>

    );
};

export default EarthReactSimple;