import React, { useState, useEffect } from "react";

const PhishingDomain = ({ veri }) => {
    const [phishing, setPhishing] = useState([]);

    useEffect(() => {
        setPhishing(veri?.top_phishing_domain
            || []);
    }, [veri]);

    return (
        <div style={{ padding: "5px", maxWidth: "400px", margin: "0 auto", height: '280px' }}>
            {/* Başlık */}
            <h6 className="titleAttackGonderen">
                Domains that send Phishing emails
            </h6>

            {/* Maillerin Listesi */}
            {phishing.map((phishingItem, index) => {
                const domain = phishingItem[0];
                const count = phishingItem[1];
                const firstLetter = domain.charAt(0).toUpperCase(); // İlk harfi büyük harf yap

                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "7px",
                            padding: "3px",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            cursor: "pointer",
                        }}
                    >
                        {/* İlk Harfi Gösteren Yuvarlak */}
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "red",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "4px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                            }}
                        >
                            {firstLetter}
                        </div>

                        {/* Mail Adresi */}
                        <div style={{ flex: 1 }}>
                            <strong style={{ fontSize: "12px", color: "#333" }}>
                                {domain}
                            </strong>
                        </div>

                        {/* Atak Sayısı */}
                        <div>
                            <span style={{ fontSize: "12px", color: "#666" }}>
                                {count} attack
                            </span>
                        </div>
                    </div>
                );
            })}

            {/* Açıklama */}
            <p style={{ textAlign: "left", marginTop: "10px", color: "#666", fontSize: "11px" }}>
                This section shows the domains that have sent the most phishing emails to our system. These domains represent attacks designed to steal user credentials or sensitive information. You can reduce the damage by adding these domains to the blacklists in our system.                                   </p>
        </div>
    );
};

export default PhishingDomain;
