import React, { useState, useEffect,useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import RaporDeneme from './RaporDeneme';
import { useDispatch } from "react-redux";
import { getReportData } from '../../store/actions/reporting';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

function ReportingPage() {

    const [dates, setDates] = useState({ start_date: null, end_date: null });
    const [dateRange, setDateRange] = useState({ start_date: null, end_date: null });
    const [reportData, setReportData] = useState({ start_date: null, end_date: null });
    const [tableData, setTableData] = useState([]);

    const [raporVeri, setRaporVeri] = useState(null);
    const [success, setSuccess] = useState(false);
    const [listed, setListed] = useState(false);
    const [create, setCreate] = useState(false);
    const toast = useRef(null);  // Toast referansı

    const dispatch = useDispatch();

    useEffect(() => {
        if (create) {
            let data = [
                {
                    reportRange: `${dateRange.start_date} / ${dateRange.end_date}`,
                    score: raporVeri.score
                }
            ]
            console.log("data", data);
            setTableData(data)
            setDates({ start_date: null, end_date: null })


        }

    }, [listed])



    const onDateChange = (e) => {
        let date = e.target.value
        let tarih = null;
        let raporTarih = null;
        const day = date.getDate();
        const dayLast = date.getDate() + 1;
        const month = date.getMonth() + 1; // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
        const year = date.getFullYear();
        console.log("e.target.name", e.target.name, dayLast);
        tarih = (e.target.name === 'end_date' ? `${dayLast}.${month}.${year}` : `${day}.${month}.${year}`);
        raporTarih = (e.target.name === 'end_date' ? `${day}.${month}.${year}` : `${day}.${month}.${year}`);
        setDateRange({ ...dateRange, [e.target.name]: raporTarih })
        setDates({ ...dates, [e.target.name]: e.target.value });
        setReportData({ ...reportData, [e.target.name]: tarih })
    };



    const createReport = () => {
        const startDate = reportData.start_date;
        const endDate = reportData.end_date;

        // Tarihleri 'dd.mm.yyyy' formatından Date nesnesine dönüştürmek
        const parseDate = (dateString) => {
            const [day, month, year] = dateString.split('.').map(Number);
            return new Date(year, month - 1, day); // Aylar 0 bazlı olduğu için (month - 1) yapıyoruz
        };

        const start_date = parseDate(startDate);
        const end_date = parseDate(endDate);

        // Tarih karşılaştırma
        if (end_date < start_date) {
            toast.current.show({ severity: 'error', summary: 'Invalid Date Range', detail: 'End date cannot be earlier than start date!', life: 3000 });
            setDates({ start_date: null, end_date: null })
            
        } else {
            dispatch(getReportData(reportData)).then((res) => {
                console.log("res241", res);
                setRaporVeri(res)
                // setSuccess(true)
                setCreate(true)
                setListed(!listed)
            })

        }


    };
    const actionTemplate = (rowData) => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* Download Button */}
                    {/* <div id={`tooltip_download_${rowData.id}`} style={{ display: 'inline-block', marginRight: '10px' }}>
                        <Button
                            icon="pi pi-download"
                            className="p-button-rounded p-button-info"
                            onClick={() => setSuccess(1)}
                        />
                    </div>
                    <Tooltip target={`#tooltip_download_${rowData.id}`} content="Download as PDF" position="top" /> */}

                    {/* View Button */}
                    <div id={`tooltip_view_${rowData.id}`} style={{ display: 'inline-block' }}>
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-success"
                            onClick={() => setSuccess(true)}
                        />
                    </div>
                    <Tooltip target={`#tooltip_view_${rowData.id}`} content="View Report" position="top" />
                </div>

            </>
        );
    };

    const getSeverity = (score) => {
        switch (score) {
            case 'E':
                return 'darkred';

            case 'D':
                return 'red';

            case 'C':
                return 'orange';

            case 'B':
                return 'yellow';

            case 'A':
                return 'green';
        }
    };

    const scoreTemplate = (rowData) => {
        return <Tag style={{ width: '50px', backgroundColor: getSeverity(rowData.score) }} value={rowData.score} />;
    };

    return (
        <>
            <Toast ref={toast} /> {/* Toast Bileşeni */}
            <div className="card">
                <div className="p-col-12">
                    <h6>Reporting Service</h6>

                    <div style={{ border: '2px solid', borderRadius: '10px' }} className="flex justify-content-start ">
                        <div style={{ width: '400px' }} className="card flex flex-column gap-2">
                            <label htmlFor="startDate">Start Date <i style={{ color: 'red' }}>*</i></label>
                            <Calendar dateFormat='dd/mm/yy' id="start_date" name="start_date" value={dates.start_date} onChange={onDateChange} showButtonBar />
                            <small id="username-help">
                                Enter the starting date of report.
                            </small>
                        </div>
                        <div style={{ width: '400px' }} className="card flex flex-column gap-2">
                            <label htmlFor="endDate">End Date <i style={{ color: 'red' }}>*</i> </label>
                            <Calendar dateFormat='dd/mm/yy' id="end_date" name="end_date" value={dates.end_date} onChange={onDateChange} showButtonBar />
                            <small id="username-help">
                                Enter the last date of report.
                            </small>
                        </div>

                        <div style={{ width: '300px' }} className="card flex flex-column gap-2">
                            <label>&zwnj;</label>
                            <Button label="Create Report" onClick={createReport} className="p-button-outlined " />

                        </div>

                    </div>
                </div>

            </div>
            <div className="card">
                {/* <h6>Report List</h6> */}
                {
                    success > 0 && <RaporDeneme veri={raporVeri} reportData={reportData} dateRange={dateRange} success={success} />
                }

                <div style={{ border: '2px solid', borderRadius: '10px' }} className="p-col-12 mt-2">
                    <DataTable
                        value={tableData}
                        tableStyle={{ minWidth: '%100', minHeight: '7rem' }}
                        emptyMessage='There is no report.'
                        showGridlines
                    >
                        <Column alignHeader={'center'} style={{ width: '40%', textAlign: 'center' }} field="reportRange" header="Report Date Range"></Column>
                        <Column alignHeader={'center'} style={{ width: '30%', textAlign: 'center' }} field="score" header="Score" body={scoreTemplate} ></Column>
                        <Column alignHeader={'center'} style={{ width: '30%', textAlign: 'center' }} header="Action" body={actionTemplate} ></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default ReportingPage;
