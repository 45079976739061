import { Chart } from 'primereact/chart';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import CountryList from './CountryList';
import AttackMailAlici from './AttackMailAlici';
import AttackMailGonderen from './AttackMailGonderen';
import SpamMailAlici from './SpamMailAlici';
import SpamMailGonderen from './SpamMailGonderen';
import DataCards from './DataCards';
import WordList from './WordList';
import SpamDomain from './SpamDomain';
import PhishingDomain from './PhishingDomain';
import { Button } from "primereact/button";

const RaporDeneme = ({ veri, reportData, dateRange, success }) => {
    const reportRef1 = useRef();
    const reportRef2 = useRef();
    console.log("veri", veri);

    const colorA = "linear-gradient(135deg, white, lightgray, lightgreen)";
    const colorB = "linear-gradient(135deg, white, lightgray, yellow)";
    const colorC = "linear-gradient(135deg, white, lightgray, orange)";
    const colorD = "linear-gradient(135deg, white, lightgray, red)";
    const colorE = "linear-gradient(135deg, white, lightgray, darkred)";

    const [scoreColor, setScoreColor] = useState('black');

    useEffect(() => {
        if (veri?.score) {
            const color = veri.score === 'A' ? colorA :
                veri.score === 'B' ? colorB :
                    veri.score === 'C' ? colorC :
                        veri.score === 'D' ? colorD :
                            veri.score === 'E' ? colorE : 'black';

            setScoreColor(color);
        }

    }, [veri.score]);



    const downloadPDF = () => {
        console.log("içerde");
        const input1 = reportRef1.current;
        const input2 = reportRef2.current;

        const pdf = new jsPDF('p', 'mm', 'a4');

        html2canvas(input1).then(canvas1 => {
            const imgData1 = canvas1.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas1.height * imgWidth) / canvas1.width;

            pdf.addImage(imgData1, 'PNG', 0, 0, imgWidth, imgHeight);

            html2canvas(input2).then(canvas2 => {
                const imgData2 = canvas2.toDataURL('image/png');
                pdf.addPage();
                pdf.addImage(imgData2, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save('xGuard Report.pdf');


            });
        });

    };


    return (

        <>
            <div className='flex justify-content-center m'>
                <Button label='Download as PDF' onClick={() => downloadPDF()} />
            </div>


            <div className='card'>

                <div ref={reportRef1} style={{
                    width: '210mm',
                    height: '297mm',
                    margin: 'auto',
                    backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    borderRadius: '10px',
                    flexDirection: 'column',
                    position: 'relative',
                    zIndex: 9999,
                    padding: '20px',
                    overflow: 'hidden',
                    display: 'flex',


                }}
                    className='card'>

                    {/* Logo ve Tarih - Ortada */}
                    <div
                        style={{
                            textAlign: 'center',
                            marginBottom: '20px',
                            padding: '10px',
                            background: scoreColor,
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <img
                            src='/assets/layout/images/ssx.png'
                            alt="Logo"
                            style={{ width: '300px', height: 'auto' }}
                        />
                        <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                            {/* Report Date: 06.10.2024 - 10.10.2024 */}
                            Threat Intelligence Report
                        </p>

                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '130px',
                                backgroundImage: 'url("assets/layout/images/shield1.png")',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                margin: '0 auto',
                                textAlign: 'center',
                            }}
                        >
                            <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <small
                                    className='mb-0 p-0 mr-1'
                                    style={{
                                        fontSize: '40px',
                                        color: veri?.score === 'A' ? 'green' :
                                            veri?.score === 'B' ? 'yellow' :
                                                veri?.score === 'C' ? 'orange' :
                                                    veri?.score === 'D' ? 'red' : 'darkred', // Diğer durumlar için siyah renk
                                    }}
                                >
                                    {veri?.score}
                                </small>

                                <small className='p-0 m-0'
                                    style={{
                                        fontSize: '15px', // Küçük font boyutu
                                        color: 'black', // Beyaz yazı rengi
                                    }}
                                >
                                    Score
                                </small>
                            </div>

                        </div>



                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 15px',
                                marginTop: '10px',
                            }}

                        >

                            <small

                                style={{
                                    fontSize: '14px',
                                    color: '#006400',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    transition: 'color 0.3s ease',
                                }}

                            >
                                {veri.company_domain}

                            </small>

                        </div>
                        <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                            {/* Report Created Date: {new Date().toLocaleString()} */}
                        </p>
                        <p className='m-0' style={{ fontSize: '11px', fontWeight: 'bold' }}>
                            Report Date Range: {dateRange?.start_date} / {dateRange?.end_date}
                        </p>
                    </div>


                    {/* İçerik Bölümü */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        position: 'relative',
                        padding: 0
                    }}>
                        {/* Sol Taraf (Grafikler ve CountryList) */}
                        <div style={{ width: '50%', paddingRight: '10px', textAlign: 'left' }}>
                            <CountryList veri={veri} />
                            <AttackMailAlici veri={veri} />
                        </div>

                        {/* Dikey Çizgi */}
                        <div style={{
                            height: '100%',
                            borderLeft: '2px solid black',
                            position: 'absolute',
                            left: '50%',
                            top: 0,
                        }}></div>

                        {/* Sağ Taraf (Rapor İçeriği) */}
                        <div style={{ width: '50%', paddingLeft: '10px', textAlign: 'left' }}>
                            <DataCards veri={veri} />
                            <AttackMailGonderen veri={veri} />

                        </div>
                    </div>
                </div>

                {/* İkinci Sayfa */}
                <div ref={reportRef2} style={{
                    width: '210mm',
                    height: '297mm',
                    margin: 'auto',
                    backgroundColor: 'white',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    zIndex: 9999,
                    padding: '20px',
                    overflow: 'hidden',
                    marginTop: '20px' // İkinci sayfa üstüne boşluk ekleriz
                }}
                    className='card'>

                    {/* Logo ve Tarih - Ortada */}
                    <div
                        style={{
                            textAlign: 'center',
                            marginBottom: '20px',
                            padding: '2px',
                            background: scoreColor,
                            borderRadius: '10px', // Köşeleri yuvarlamak için
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Hafif gölge efekti
                        }}
                    >
                        <img
                            src='/assets/layout/images/ssx.png'
                            alt="Logo"
                            style={{ width: '300px', height: 'auto' }}
                        />
                        <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                            Report Date: 06.10.2024 - 10.10.2024
                        </p>
                    </div>

                    {/* İçerik Bölümü */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        position: 'relative',
                    }}>
                        {/* Sol Taraf (Grafikler ve CountryList) */}
                        <div style={{ width: '50%', paddingRight: '10px', textAlign: 'left' }}>
                            <SpamMailAlici veri={veri} />
                            <PhishingDomain veri={veri} />
                        </div>

                        {/* Dikey Çizgi */}
                        <div style={{
                            height: '100%',
                            borderLeft: '2px solid black',
                            position: 'absolute',
                            left: '50%',
                            top: 0,
                        }}></div>

                        {/* Sağ Taraf (Rapor İçeriği) */}
                        <div style={{ width: '50%', paddingLeft: '10px', textAlign: 'left' }}>
                            <SpamMailGonderen veri={veri} />
                            <SpamDomain veri={veri} />
                        </div>
                    </div>

                    {/* Altına Yeni Bileşen Ekleniyor */}
                    <div style={{
                        marginTop: '40px', // Üstüne boşluk ekleyelim
                        padding: '5px',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '10px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}>
                        {/* Yeni Bileşeni Buraya Ekleyin */}
                        <WordList wordlist={veri.top_matched_words} />
                    </div>
                </div>

            </div>

        </ >
    );
}

export default RaporDeneme;
