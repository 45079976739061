import React, { useState, useRef, useEffect } from 'react';
import { TabMenu } from 'primereact/tabmenu';

import { Toast } from 'primereact/toast';
import './settingStyle.css'
import YaraRules from './YaraRules';
import Spf from './Spf';
import { Menubar } from 'primereact/menubar';
import Filtretion from './Filtretion';
import FileSize from './FileSize';
import ContentWords from './ContentWords';
import FileExtensions from './FileExtensions';
import SenderDomainFilter from './SenderDomainFilter';

const Setting = ({ labels }) => {

    const itemsUst = [

        { label: 'File', icon: 'pi pi-file', code: 0 },
        { label: 'Mail Body', icon: 'pi pi-envelope', code: 1 },
        { label: 'Sender Domain', icon: 'pi pi-cloud', code: 2 },


    ];



    const senderDomainTabs = {
        spf: true,
        domainFilter: false,
    }
    const fileTabsHeader = {
        fileSize: true,
        fileExtensions: false,
        yaraRules: false
    }
    const mailBodyTabsHeader = {
        contentFilter: true
    }



    const [senderDomainTab, setSenderDomainTab] = useState(senderDomainTabs);
    const [fileTab, setFileTab] = useState(fileTabsHeader);
    const [mailBodyTab, setMailBodyTab] = useState(mailBodyTabsHeader);
    const [activeIndexUst, setActiveIndexUst] = useState(0);
    const toast = useRef(null);
    const [activeItem, setActiveItem] = useState('');

    const fileSettingItem = [
        {
            label: 'File Size',
            icon: 'pi pi-file',
            command: () => setFileTab({
                ...fileTab,
                fileSize: true,
                fileExtensions: false,
                yaraRules: false,

            })

        }, {
            label: 'File Extension ',
            icon: 'pi pi-ban',
            command: () => {
                setFileTab(fileTab => ({
                    ...fileTab,
                    fileExtensions: true,
                    fileSize: false,
                    yaraRules: false
                }));
                setActiveItem('File Extension');
            }


        }, {
            label: 'YARA Rules',
            icon: 'pi pi-eye',
            command: () => {
                setFileTab(fileTab => ({
                    ...fileTab,
                    yaraRules: true,
                    fileExtensions: false,
                    fileSize: false,
                }));
                setActiveItem('YARA Rules');
            }


        }
    ]
    const mailBodyItem = [
        {
            label: 'Content Filter ',
            icon: 'pi pi-inbox',
            command: () => setMailBodyTab({
                ...mailBodyTab,
                contentFilter: true
            })

        }
    ]

    const senderDomainItem = [

        {
            label: 'SPF',
            icon: 'pi pi-slack',
            command: () => setSenderDomainTab({
                ...senderDomainTab,
                spf: true,
                domainFilter: false

            })

        },
        {
            label: 'Domain / Email Filter',
            icon: 'pi pi-filter',
            command: () => setSenderDomainTab({
                ...senderDomainTab,
                domainFilter: true,
                spf: false,
            })

        },
    ]



    useEffect(() => {
        if (labels === 'File') {
            setActiveIndexUst(0)


        } else if (labels === 'Mail Body') {
            setActiveIndexUst(1)

        }
        else if (labels === 'Sender Domain') {
            setActiveIndexUst(2)
        }

    }, [labels])

    const ustTabModelSelect = (e) => {
        setActiveIndexUst(e?.value?.code)
    }


    return (
        <div>
            <Toast ref={toast} />
            <div style={{ height: 'auto' }} className="card-settings">
                <h5>Mail Settings</h5>
                <TabMenu model={itemsUst} activeIndex={activeIndexUst} onTabChange={(e) => ustTabModelSelect(e)} />
                {
                    activeIndexUst === 0 ? (
                        <div className='mt-2'>
                            <Menubar model={fileSettingItem} />
                            
                            {
                                fileTab.fileSize ? (
                                    <>
                                        <FileSize />
                                    </>
                                ) : fileTab.fileExtensions ? (
                                    <>
                                        <FileExtensions />
                                    </>
                                ) : fileTab.yaraRules ? (
                                    <>
                                        <YaraRules />
                                    </>
                                ) : <>
                                </>
                            }
                        </div>
                    ) : activeIndexUst === 1 ? (
                        <div className='mt-2'>
                            <Menubar model={mailBodyItem} />
                            {
                                mailBodyTab.contentFilter ? (
                                    <>
                                        <ContentWords />

                                    </>
                                ) : (
                                    <>

                                    </>
                                )
                            }

                        </div>


                    ) : activeIndexUst === 2 ? (
                        <>
                            <div className='mt-2'>
                                <Menubar model={senderDomainItem} />
                                {

                                    senderDomainTab.spf ? (
                                        <>
                                            <Spf />
                                        </>
                                    ) : senderDomainTab.domainFilter ? (
                                        <>
                                            <SenderDomainFilter />
                                        </>
                                    ) :
                                        <>
                                        </>
                                }
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default Setting

