import { Button } from 'primereact/button';
import React, { useState } from 'react';

const Notifications = () => {
    // Bildirimler listesi
    const appNotifications = [
        'Phishing mail caught'

    ];
    // const appNotifications = [
    //     'Phishing mail caught',
    //     'Spam Mail',
    //     'Re-Send Mail Event',

    // ];
    const userNotifications = [
        'New user registered',
        'Password changed',


    ];

    // Seçilen bildirimleri tutmak için state
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [selectedNotificationsUsers, setSelectedNotificationsUsers] = useState([]);

    // Bildirim seçildiğinde div içerisine ekler
    const handleNotificationSelect = (notification) => {
        if (!selectedNotifications.includes(notification)) {
            setSelectedNotifications([...selectedNotifications, notification]);
        }
    };
    const handleNotificationSelectUser = (notification) => {
        if (!selectedNotifications.includes(notification)) {
            setSelectedNotificationsUsers([...selectedNotificationsUsers, notification]);
        }
    };

    // Seçilen bildirimi kaldırma
    const handleRemoveNotification = (notification) => {
        setSelectedNotifications(selectedNotifications.filter(n => n !== notification));
    };
    const handleRemoveNotificationUser = (notification) => {
        setSelectedNotificationsUsers(selectedNotificationsUsers.filter(n => n !== notification));
    };

    return (
        <div className='card' style={{ padding: '20px', minHeight: '100%' }}>
            {/* Başlık */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 style={{ textAlign: 'left' }}>Event Notification</h3>
                {/* {
                    (selectedNotifications.length > 0 || selectedNotificationsUsers.length > 0) &&
                    <Button label="Save Notifications" icon="pi pi-save" className="p-button-outlined" onClick={() => {
                        setSelectedNotifications([]);
                        setSelectedNotificationsUsers([]);
                    }} />
                } */}
            </div>

            {/* Mesaj kutusu */}
            <div style={{
                backgroundColor: '#ebdcdc',
                padding: '10px',
                borderRadius: '5px',
                width: '100%',
                margin: '20px 0',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                color: 'black'
            }}>
                <p style={{ fontSize: '14px' }} className='m-2'>On this page, you can manage how you receive notifications related to both user activities and key application events. You have full control over which notifications you want to enable or disable. Notifications help you stay informed about critical events and user actions happening within the application.
                </p>
            </div>

            {/* Bildirimler listesi */}
            <div className='p-2' style={{ background: '#e5e5e5', borderRadius: '10px' }} >

                <p style={{ color: 'black' }}>App events notification options</p>
                <small className='m-0' style={{ color: 'black', fontSize: "11px" }}>Stay up to date with system updates, feature releases, payment confirmations, and more. Customize how you receive notifications for these key events so you never miss important information.</small>
                <hr></hr>
                <smal>By default, phishing emails will be reported to the system user.</smal>
                <ul style={{
                    display: 'flex',
                    flexWrap: 'wrap',  // Bildirimlerin ekran taşmadan aşağıya geçmesini sağlar
                    listStyleType: 'none',
                    padding: '0',
                    marginBottom: '20px',
                    gap: '10px',
                    maxWidth: '100%'
                }}>
                    {appNotifications.map((notification, index) => (
                        <li key={index}
                            style={{
                                padding: '10px',
                                backgroundColor: '#dfd7d7',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                color: 'black'
                            }}
                            onClick={() => handleNotificationSelect(notification)}
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#d1d1d1'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = '#ffd978'}
                        >
                            {notification}
                        </li>
                    ))}
                </ul>

                {/* <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    padding: '10px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '10px',
                    minHeight: '50px',
                    alignItems: 'center',
                    marginTop: '10px'
                }}>
                    {selectedNotifications.length > 0 ? (
                        selectedNotifications.map((notification, index) => (
                            <div key={index} style={{

                            }}>
                                <Button
                                    label={notification}
                                    icon="pi pi-times"
                                    onClick={() => handleRemoveNotification(notification)}
                                    className='user-notification'
                                    iconPos='right'/>

                            </div>
                        ))
                    ) : (
                        <span style={{ color: 'black' }} >No app notifications selected</span>
                    )}
                </div> */}
            </div>

            {/* <div className='p-2 mt-4' style={{ background: '#e5e5e5', borderRadius: '10px' }} >

                <p style={{ color: 'black' }}>User events notification options</p>
                <small className='m-0' style={{ color: 'black', fontSize: "11px" }}>Set up alerts for specific user actions such as registration, password changes, or any other critical updates. This ensures that you are always aware of important changes made by users in your application.</small>
                <ul style={{
                    display: 'flex',
                    flexWrap: 'wrap',  // Bildirimlerin ekran taşmadan aşağıya geçmesini sağlar
                    listStyleType: 'none',
                    padding: '0',
                    marginBottom: '20px',
                    gap: '10px',
                    maxWidth: '100%'
                }}>
                    {userNotifications.map((notification, index) => (
                        <li key={index}
                            style={{
                                padding: '10px',
                                backgroundColor: '#ffb078',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                color: 'black'
                            }}
                            onClick={() => handleNotificationSelectUser(notification)}
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#d1d1d1'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = '#ffb078'}
                        >
                            {notification}
                        </li>
                    ))}
                </ul>

                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    padding: '10px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '10px',
                    minHeight: '50px',
                    alignItems: 'center',
                    marginTop: '10px'
                }}>
                    {selectedNotificationsUsers.length > 0 ? (
                        selectedNotificationsUsers.map((notification, index) => (
                            <div key={index} style={{

                            }}>
                                <Button
                                    label={notification}
                                    icon="pi pi-times"
                                    onClick={() => handleRemoveNotificationUser(notification)}
                                    className='user-notification'
                                    iconPos='right' />

                            </div>
                        ))
                    ) : (
                        <span style={{ color: 'black' }} >No user notifications selected</span>
                    )}
                </div>
            </div> */}
        </div >
    );
};

export default Notifications;
