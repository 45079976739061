import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { getEvents } from '../../services/Api/userAction';
import Loading from '../UI/Loading';
import { Column } from 'primereact/column';
import DateFormat from '../DateFormat';
import { useDispatch } from 'react-redux';

const UserActionLogs = () => {
    const [action, setAction] = useState();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEvents).then((res) => {
            if (res?.data?.results?.data) {
                setLoading(false)
                const sortedDates = res?.data?.results?.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setAction(sortedDates.filter(item => item.action_type !== "User Activity"));
            }

        });
    }, []);

    const timestamp = (rowData) => {

        return (
            <>
                <DateFormat dateString={rowData?.timestamp} />
            </>
        )
    }
    return (
        <div style={{ height: '31rem' }} className='card'>
            {
                loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <DataTable
                        header='User Activity'
                        value={action}
                        scrollHeight='350px'
                        className='data-table-container'
                        emptyMessage='There is no daily e-mail traffic.'
                        showGridlines
                    >
                        <Column
                            style={{ fontSize: '10px', textAlign: 'left', textAlign: 'center' }}
                            alignHeader={'center'}
                            field="username"
                            header="User"
                            
                        ></Column>
                        <Column
                            style={{ fontSize: '10px', textAlign: 'left', textAlign: 'center' }}
                            alignHeader={'center'}
                            field="action_details"
                            header="Action"
                        ></Column>
                        <Column
                            style={{ fontSize: '10px', textAlign: 'left', textAlign: 'center' }}
                            alignHeader={'center'}
                            header="Time"
                            body={timestamp}></Column>
                    </DataTable>
                )
            }


        </div>)
}

export default UserActionLogs