import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { classNames } from "primereact/utils";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppBreadcrumb from "./AppBreadcrumb";
import AppMenu from "./AppMenu";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import { Dashboard, Events } from "./components";
import MalwareBazaar from "./components/MalwareBazaar/MalwareBazaar";
import BruteForce from "./components/BruteForce/BruteForce";
import Setting from "./components/Setting/Setting";

import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import AntiPhishing from "./components/AntiPhishing/AntiPhishing";
import UrlAnalyzer from "./components/UrlAnalyzer/UrlAnalyzer";
import SenderDomainAnalyzer from "./components/SenderDomainAnalyzer/SenderDomainAnalyzer";
import Profile from "./components/Setting/Profile";
import SystemSetting from "./components/Setting/SystemSetting";
import Reporting from "./components/Reporting/Reporting";
import AppRightMenu from "./AppRightMenu";
import AppConfig from "./AppConfig";
import Notifications from "./components/Notifications/Notifications";
// import axios from "axios";

const App = (props) => {
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [menuMode] = useState('sidebar');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [ripple] = useState(true);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] =
    useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [pinActive, setPinActive] = useState(false);
  const [labels, setLabels] = useState(null);
  const [activeInlineProfile, setActiveInlineProfile] = useState(false);
  const [visible, setVisible] = useState(false)

  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [dashboardType, setDashboardType] = useState(1);
  const copyTooltipRef = useRef();
  const location = useLocation();
  const queryClient = new QueryClient();
  const [serverUrl, setServerUrl] = useState()
  PrimeReact.ripple = true;
  const themeMode = props.colorScheme;
  console.log("asd", props.colorScheme);
  // useEffect(() => {
  //   axios.get('http://162.19.228.26:8085/getAlert/1').then((res) => {
  //     console.log(res, "ressss1s");

  //     if (res?.status === 200) {
  //       console.log(res, "resssss");
  //       setServerUrl(res?.data?.Alarm.match(/'([^']*)'/)[1])

  //     }
  //   })
  // }, [])
  console.log("serverUrl", serverUrl);
  const menu = [
    {
      label: "Dashboard",
      icon: "pi pi-home",
      to: "/",
    },
    {
      label: "Events",
      icon: "pi pi-bolt",
      to: "/events",
    },
    {
      label: "Notifications",
      icon: "pi pi-bell",
      to: "/notifications",
    },
    {
      label: "Reporting",
      icon: "pi pi-book",
      to: "/reporting",
    },
    // xPhish kısmı
    // {
    //   label: "xPhish",
    //   icon: "pi pi-envelope",
    //   items: [
    //     { label: "Simulations", icon: "pi pi-send", to: "/simulations" },
    //     { label: "Templates", icon: "pi pi-book", to: "/templates" },
    //     { label: "Landings", icon: "pi pi-globe", to: "/landings" },
    //     { label: "SMTPs", icon: "pi pi-ticket", to: "/smtps" },
    //   ],
    // },

    // {
    //   label: "Leak Service",
    //   icon: "pi pi-database",
    //   to: "/leakservice",
    // },
    // {
    //   label: "Hash Box",
    //   icon: "pi pi-file-o",
    //   to: "/hashbox",
    // },
    // {
    //   label: "URL Analyzer",
    //   icon: "pi pi-link",
    //   to: "/url-analyzer",
    // },
    // {
    //   label: "SD Analyzer",
    //   icon: "pi pi-link",
    //   to: "/senderdomain-analyzer",
    // },
    // {
    //   label: "E-Mail Analysis",
    //   icon: "pi pi-angle-double-up",
    //   to: "/mail-analysis",
    // },
    {
      label: "Analyzed",
      icon: "pi pi-bookmark",
      items: [
        { label: "Mail", icon: "pi pi-angle-double-up", to: "/mail-analysis" },
        { label: "Files", icon: "pi pi-file-o", to: "/file-analysis" },
        { label: "Url", icon: "pi pi-link", to: "/url-analyzer" },
        { label: "Domain", icon: "pi pi-send", to: "/senderdomain-analyzer" },

      ],
    },

    {
      label: "Settings",
      icon: "pi pi-cog",
      // items: [
      //   { label: "General", icon: "pi pi-cog", to: "/settings" },
      //   { label: "File", icon: "pi pi-file", to: "/settings" },
      //   { label: "Mail", icon: "pi pi-envelope", to: "/settings" },
      //   { label: "Filtretion", icon: "pi pi-filter", to: "/settings" }

      // ],
      items: [
        {
          label: "Mail", icon: "pi pi-envelope",
          items: [
            // { label: "General", icon: "pi pi-globe", to: "/mail-settings" },
            { label: "File", icon: "pi pi-file", to: "/mail-settings" },
            { label: "Mail Body", icon: "pi pi-envelope", to: "/mail-settings" },
            { label: "Sender Domain", icon: "pi pi-cloud", to: "/mail-settings" }
          ]
        },
        {
          label: "System", icon: "pi pi-desktop",
          items: [
            // { label: 'System Updates', icon: 'pi pi-home', to: "/system-settings"},
            // { label: 'Backup and Restore', icon: 'pi pi-directions-alt', to: "/system-settings" },
            { label: 'Security', icon: 'pi pi-key', to: "/system-settings" },
            // { label: 'Performance Monitoring', icon: 'pi pi-desktop', to: "/system-settings" },
            // { label: 'User Management', icon: 'pi pi-users', to: "/system-settings" },
          ]
        }
      ],
    }

    // {
    //   label: "Logout",
    //   icon: "pi pi-power-off",
    //   to: "/signIn",
    // },

  ];

  const routes = [
    { icon: "pi pi-home", parent: "", label: "Dashboard" },
    // { icon: "pi pi-send", parent: "xPhish", label: "Simulations" },
    // { icon: "pi pi-book", parent: "xPhish", label: "Templates" },
    // { icon: "pi pi-globe", parent: "xPhish", label: "Landings" },
    // { icon: "pi pi-ticket", parent: "xPhish", label: "SMTPs" },
    // { icon: "pi pi-database", parent: "Services", label: "Leak Service" },
    { icon: "pi pi-spin pi-spinner", parent: "", label: "Events" },

    { icon: "pi pi-file-o", parent: "Services", label: "File Analysis" },
    { icon: "pi pi-link", parent: "Services", label: "URL Analyzer" },
    { icon: "pi pi-link", parent: "Services", label: "SD Analyzer" },
    { icon: "pi pi-bolt", parent: "Services", label: "Brute Force" },
    { icon: "pi pi-angle-double-up", parent: "Services", label: "Mail Analysis" },
    // { icon: "pi pi-shield", parent: "Home", label: "Private Intelligence" },
    // { icon: "pi pi-users", parent: "Home", label: "Emails" },
    { icon: "pi pi-setting", parent: "", label: "Settings" },
    // { icon: "pi pi-book", parent: "", label: "Reporting" },
    { icon: "pi pi-profile", parent: "", label: "Profile" },
  ];

  let rightMenuClick;
  let configClick;
  let menuClick;
  let searchClick = false;
  let topbarItemClick;
  let topbarNotificationClick;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!topbarItemClick) {
      setTopbarMenuActive(false);
    }

    if (!topbarNotificationClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
        setResetActiveIndex(true);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
      }

      hideOverlayMenu();
      unblockBodyScroll();
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    topbarItemClick = false;
    topbarNotificationClick = false;
    menuClick = false;
    configClick = false;
    rightMenuClick = false;
    searchClick = false;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const onRightMenuButtonClick = () => {
    rightMenuClick = true;
    setRightMenuActive(true);
  };

  const onRightMenuClick = () => {
    rightMenuClick = true;
  };

  const onRightMenuActiveChange = (active) => {
    setRightMenuActive(active);
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (isOverlay()) {
      setOverlayMenuActive((prevState) => !prevState);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevState) => !prevState);
    } else {
      setStaticMenuMobileActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onTopbarItemClick = (event) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onTopbarNotificationClick = (event) => {
    topbarNotificationClick = true;
    setTopbarNotificationMenuActive((prevState) => !prevState);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onToggleMenu = (event) => {
    menuClick = true;

    if (overlayMenuActive) {
      setOverlayMenuActive(false);
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarMouseOver = (e) => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setSidebarActive(isDesktop());
      setTimeout(() => {
        setPinActive(isDesktop());
      }, 200);
    }
  };
  console.log("sidebarActive", sidebarActive);
  const onSidebarMouseLeave = (e) => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false);
        setPinActive(false);
      }, 250);
    }
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onChangeActiveInlineMenu = (event) => {
    setActiveInlineProfile((prevState) => !prevState);
    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event) => {
    console.log("event32222", event);
    setLabels(event.item.label)
    if (!event.item.items) {
      hideOverlayMenu();
      setResetActiveIndex(true);
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };
  const onConfigButtonClick = (event) => {
    setConfigActive((prevState) => !prevState);
    configClick = true;
    event.preventDefault();
  };
  const onConfigClick = () => {
    configClick = true;
  };
  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const onInputClick = () => {
    searchClick = true;
  };

  const breadcrumbClick = () => {
    searchClick = true;
    setSearchActive(true);
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const layoutClassName = classNames("layout-wrapper", {
    "layout-static": menuMode === "static",
    "layout-overlay": menuMode === "overlay",
    "layout-overlay-active": overlayMenuActive,
    "layout-slim": menuMode === "slim",
    "layout-horizontal": menuMode === "horizontal",
    "layout-active": menuActive,
    "layout-mobile-active": staticMenuMobileActive,
    "layout-sidebar": menuMode === "sidebar",
    "layout-sidebar-static": menuMode === "sidebar" && sidebarStatic,
    "layout-static-inactive":
      staticMenuDesktopInactive && menuMode === "static",
    "p-ripple-disabled": !ripple,
  });

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <div className="layout-main">
        <AppTopbar
          items={menu}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          menuActive={menuActive}
          topbarMenuActive={topbarMenuActive}
          topbarNotificationMenuActive={topbarNotificationMenuActive}
          activeInlineProfile={activeInlineProfile}
          onTopbarItemClick={onTopbarItemClick}
          onTopbarNotificationClick={onTopbarNotificationClick}
          onMenuButtonClick={onMenuButtonClick}
          onToggleMenu={onToggleMenu}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          onMenuClick={onMenuClick}
          onMenuItemClick={onMenuItemClick}
          onRootMenuItemClick={onRootMenuItemClick}
          resetActiveIndex={resetActiveIndex}

        />

        <AppMenu
          model={menu}
          onRootMenuItemClick={onRootMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          onToggleMenu={onToggleMenu}
          onMenuClick={onMenuClick}
          menuMode={menuMode}
          colorScheme={props.colorScheme}
          changeColorScheme={props.onColorSchemeChange}
          menuActive={menuActive}
          sidebarActive={sidebarActive}
          sidebarStatic={sidebarStatic}
          pinActive={pinActive}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onSidebarMouseOver={onSidebarMouseOver}
          activeInlineProfile={activeInlineProfile}
          onChangeActiveInlineMenu={onChangeActiveInlineMenu}
          resetActiveIndex={resetActiveIndex}
        />

        <AppBreadcrumb
          setVisible={setVisible}
          dashboardType={dashboardType}
          routes={routes}
          onMenuButtonClick={onMenuButtonClick}
          menuMode={menuMode}
          onRightMenuButtonClick={onRightMenuButtonClick}
          onInputClick={onInputClick}
          searchActive={searchActive}
          breadcrumbClick={breadcrumbClick}
          onConfigButtonClick={onConfigButtonClick}

        />
        {/* <AppRightMenu
          rightMenuActive={rightMenuActive}
          onRightMenuClick={onRightMenuClick}
          onRightMenuActiveChange={onRightMenuActiveChange}

        /> */}

        <div className="layout-main-content p-2">
          <QueryClientProvider client={queryClient}>
            <Switch>
              <Route path="/" exact
                render={(props) => <Dashboard {...props} serverUrl={serverUrl} dashboardType={dashboardType} setDashboardType={setDashboardType} visible={visible} setVisible={setVisible} />}
              />


              <Route path="/events" exact component={Events} />
              <Route path="/notifications" exact component={Notifications} />
              <Route path="/reporting" exact component={Reporting} />

              <Route path="/file-analysis" exact component={MalwareBazaar} />

              <Route path="/url-analyzer" exact component={UrlAnalyzer} />

              <Route path="/senderdomain-analyzer" exact component={SenderDomainAnalyzer} />

              <Route path="/mail-analysis" exact
                render={(props) => <AntiPhishing themeMode={themeMode} />}
              />
              <Route path="/mail-settings" exact
                render={(props) => <Setting labels={labels} />}
              />
              <Route path="/system-settings" exact
                render={(props) => <SystemSetting labels={labels} />}
              />
              <Route path="/profile" exact component={Profile} />


              <Route path="*">
                <Redirect to="/notfound" />
              </Route>
            </Switch>
          </QueryClientProvider>
        </div>
        <AppFooter colorScheme={props.colorScheme} />
      </div>



      <ToastContainer />
      

    </div>
  );
};

export default App;
