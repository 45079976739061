
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Tag } from 'primereact/tag';
import Colors from '../Colors';
const LineChart = ({ dashboardData, tarihAralik, dashboardType }) => {

    const [chartData1, setChartData1] = useState({});
    const [gunluk, setGunluk] = useState(false);
    const [chartOptions1, setChartOptions1] = useState({});
    const total = dashboardData?.email_analysis_count?.total;
    const totals = {};

    Object.values(dashboardData?.email_analysis_count).forEach((dayData) => {
        Object.entries(dayData).forEach(([key, value]) => {
            if (totals[key]) {
                totals[key] += value;
            } else {
                totals[key] = value;
            }
        });
    });



    const noThreatFound = totals?.no_threat_found || 0;
    const noReport = totals?.no_report || 0;

    const totalDetected = total - (noThreatFound + noReport);



    let tarihAraliği = `${tarihAralik?.start_date} - ${tarihAralik?.end_date} `


    const isDateKey = (key) => {
        return /^\d{4}-\d{2}-\d{2}$/.test(key);
    };

    const filteredData = Object.keys(dashboardData?.email_analysis_count)
        .filter(isDateKey)
        .reduce((acc, key) => {
            acc[key] = dashboardData?.email_analysis_count[key];
            return acc;
        }, {});

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    const labels = Object.keys(filteredData)
        .sort((a, b) => new Date(a) - new Date(b))
        .map(date => formatDate(date));

    const categories = ["content_words_in_blacklist", "no_report", "no_threat_found", "phishing", "spam", "attachment_bigger_than_max_size", "suspicious"];
    const capitalizeAndFormatCategory = (category) => {
        return category
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const categoryColors = {
        content_words_in_blacklist: Colors[0].cwb,
        no_report: Colors[0].nr,
        no_threat_found: Colors[0].ntf,
        phishing: Colors[0].phishing,
        spam: Colors[0].spam,
        attachment_bigger_than_max_size: Colors[0].ams,
        suspicious: Colors[0].suspicious,
    };
    const datasets = categories.map(category => {
        return {
            label: capitalizeAndFormatCategory(category),
            data: Object.keys(filteredData)
                .sort((a, b) => new Date(a) - new Date(b))
                .map(date => filteredData[date][category] || 0),
            fill: false,
            borderColor: categoryColors[category],
            tension: 0.1
        };
    });


    const chartData = {
        labels: labels,
        datasets: datasets
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },

    };


    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: [labels],
            datasets: Object.keys(filteredData[Object.keys(filteredData)[0]]).map(category => {
                return {
                    label: capitalizeAndFormatCategory(category),
                    data: Object.keys(filteredData)
                        .sort((a, b) => new Date(a) - new Date(b))
                        .map(date => filteredData[date][category] || 0),
                    fill: false,
                    borderColor: categoryColors[category],
                    backgroundColor: categoryColors[category],
                    tension: 1
                };
            })
        };
        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData1(data)
        setChartOptions1(options);
    }, []);
    return (
        <div className="card">
            <h6 className='flex justify-content-center'>{dashboardType === 0 ? 'Daily Line Chart' : dashboardType === 1 ? 'Weekly Line Chart' : 'Monthly Line Chart'} </h6>

            <div className='flex justify-content-center'>
                <h6> {tarihAraliği}</h6>
            </div>
            <div className='flex justify-content-center'>
                <Tag className='m-2' style={{ backgroundColor: '#dfe6e6', color: 'black', width: '150px' }} value={`Total Traffic ${dashboardData?.email_analysis_count?.total}`} />
                <Tag className='m-2' style={{ backgroundColor: '#dfe6e6', color: 'black', width: '150px' }} value={`Total Detected ${totalDetected}`} />

            </div>
            {
                dashboardType === 0 ? (
                    <>
                        <Chart type="bar" data={chartData1} options={chartOptions1} />
                    </>
                ) : (
                    <>
                        <Chart type="line" data={chartData} options={chartOptions} />
                    </>
                )
            }

        </div>
    )
}


export default React.memo(LineChart)