import { reportApi as api } from '../../services/Api';
import { toast } from 'react-toastify';

export const getReportData = (model) => async () => {

    try {
        const { data } = await api.getReportData(model);
        return data.data;
       
    } catch (error) {
        toast.error(error.message);
    }
    return null;
};

